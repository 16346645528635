import React, { useEffect, useState } from "react"
import { Transition } from "@headlessui/react"

const ChangingText = () => {

    let texts = [
        "USE 'GROOMD10' FOR 10% OFF ALL PRODUCTS",
        "⚡ USE 'ROUTINE20' TO SAVE 20% ON ROUTINES ⚡",
        // "⚡ USE 'GROOMD40' • 40% ON ROUTINES & GIFTING ⚡",
        // "❤ Use 'LOVE25' for 25% Off on Valentine Gifts ❤",
        "Free Shipping For Orders Over ₹595"]
    const [activeText, setActiveText] = useState(0);

    useEffect(() => {
        let intervals = setInterval(() => {
            setActiveText(prevState => {
                if (prevState === (texts.length-1)) {
                    return 0
                } else {
                    return prevState + 1
                }
            })
        }, 2500)

        return () => clearInterval(intervals);
    }, [])

    return (
        <>
            {
                texts.map((t, index) => {
                    return (
                        <Transition
                            show={activeText === index}
                            enter={`transition ease-in-out delay-150 duration-300`}
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in-out duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            key={`changing_text_${index}`}
                        >
                            <p className={"w-full flex justify-center text-center text-xs lg:text-sm text-white font-sans font-medium tracking-widest uppercase opacity-75 absolute left-1/2 transform -translate-x-1/2 h-full flex items-center"}>
                                {t}
                            </p>
                        </Transition>
                    )
                })
            }
        </>
    )

}

export default ChangingText;